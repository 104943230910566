.admin-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.admin-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.whitelist-form {
  display: flex;
  margin-bottom: 2rem;
}

.email-input {
  flex-grow: 1;
  margin-right: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button,
.remove-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button {
  background-color: #4caf50;
}

.add-button:hover {
  background-color: #45a049;
}

.remove-button {
  background-color: #f44336;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.whitelisted-users h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.whitelisted-users ul {
  list-style-type: none;
  padding: 0;
}

.whitelisted-users li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  font-size: 1.1rem;
}

.user-email {
  flex-grow: 1;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  border: none;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-icon {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.user-email:hover .action-icon {
  opacity: 1;
}

.whitelisted-user {
  background-color: #ffb3ba; /* Light pink */
}

.whitelisted-user:hover {
  background-color: #ff8c9a; /* Darker pink on hover */
}

.error {
  color: #f44336;
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.user-lists {
  display: flex;
  flex-direction: column;
}

.whitelisted-users {
  width: 100%;
  margin-bottom: 2rem;
}

.share-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.share-button:hover {
  background-color: #45a049;
}

@media (max-width: 767px) {
  .admin-page {
    padding: 1rem;
  }

  .admin-title {
    font-size: 2rem;
  }

  .whitelist-form {
    flex-direction: column;
  }

  .email-input {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .add-button,
  .remove-button {
    width: 100%;
  }

  .whitelisted-users li {
    flex-direction: column;
    align-items: stretch;
  }

  .user-email {
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
  }

  .action-icon {
    margin-left: 0.5rem;
  }
}