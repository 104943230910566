.credits-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.credits-info {
  display: flex;
  align-items: baseline;
}

.credits-count {
  font-size: 2rem;
  font-weight: bold;
  color: #d8932b;
  margin-right: 0.5rem;
}

.credits-text {
  font-size: 1rem;
  color: #666;
}

.add-credits-button {
  display: flex;
  align-items: center;
  background-color: #d8932b;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.add-credits-button:hover {
  background-color: #c17f1e;
}

.add-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.credits-cost {
  text-align: center;
  color: #666;
  margin-bottom: 1rem;
}

.input-with-button {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-with-button textarea {
  width: 100%;
  padding: 0.5rem 30px 0.5rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 38px;
  box-sizing: border-box;
  overflow-y: hidden;
}

.random-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition:
    background-color 0.3s,
    transform 0.1s ease-in-out;
}

.random-button:hover {
  background-color: #f0f0f0;
  transform: translateY(-50%) scale(1.1);
}

.random-button.rolling {
  animation: roll 0.2s linear infinite;
}

.random-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes roll {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
