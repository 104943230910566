.billing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height));
  font-family: "Quicksand", "Comfortaa", sans-serif;
}

.billing-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 2rem;
}

.billing-card h1 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.credit-packages {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.credit-package {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  width: calc(33.333% - 1rem);
  min-width: 200px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.credit-package:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.credit-package h2 {
  color: #d8932b;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.credit-package p {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 1rem;
}

.credit-package button {
  background-color: #d8932b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.credit-package button:hover {
  background-color: #c17f1e;
}

.credit-package button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #ff0000;
  text-align: center;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .credit-package {
    width: 100%;
  }
}