.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-banner {
  width: 100%;
  max-height: 50dvh;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  overflow: hidden;
}

.wordmark {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.about-content {
  text-align: center;
  max-width: 800px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.cta-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  opacity: 0.9;
}

.cta-button:first-child {
  background-color: #d8932b;
  color: white;
}

.cta-button.secondary {
  background-color: #f0f0f0;
  color: #333;
}

.made-with-love {
  margin-top: 40px;
  color: #666;
}

.legal-links {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
}

.legal-links a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s;
}

.legal-links a:hover {
  color: #333;
}

.separator {
  margin: 0 10px;
}
