@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&family=Comfortaa:wght@400;700&display=swap");

@import url("./components/NavBar.css");

.App {
  display: flex;
  justify-content: center;
  min-height: calc(100dvh - var(--navbar-height));
  margin-top: var(--navbar-height);
  background-color: #efe9da;
  font-family: "Quicksand", "Comfortaa", sans-serif;
}

.App-full {
  display: flex;
  justify-content: center;
  min-height: 100dvh;
  background-color: #efe9da;
  font-family: "Quicksand", "Comfortaa", sans-serif;
}

h1 {
  font-weight: bold;
}

.card-container {
  align-items: center;
  min-width: 464px;
  display: flex;
}

.card {
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 464px;
  margin: 2rem 0;
}

h1 {
  color: #333;
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #d8932b;
  color: #101019;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e0a143;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #ff0000;
  margin-top: 1rem;
}

.message {
  color: #666;
  margin-top: 1rem;
  text-align: center;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}

.google-sign-in {
  background-color: white;
  color: #757575;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
}

.google-sign-in:active {
  background-color: #eee;
}

.google-icon {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="%23EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/><path fill="%234285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/><path fill="%23FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/><path fill="%2334A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/><path fill="none" d="M0 0h48v48H0z"/></svg>');
  background-size: 18px;
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.card h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.card p {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #666;
}

.browse-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #d8932b;
  text-decoration: none;
}

.browse-link:hover {
  text-decoration: underline;
}

.story-thumbnail .story-lesson {
  font-size: 0.8em;
  color: #666;
  margin-top: 0.5rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  font-family: "Quicksand", "Comfortaa", sans-serif;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-logo {
  margin-bottom: 30px;
  object-fit: contain;
}

.loading p {
  font-size: 20px;
  color: #333;
  margin-top: 15px;
}

.generate-link {
  padding: 0.5rem 1rem;
  background-color: #d59554;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.generate-link:hover {
  background-color: #d28334;
}
