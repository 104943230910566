.browse-stories {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
}

.story-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.story-thumbnail {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.story-thumbnail:hover {
  transform: translateY(-5px);
}

.story-thumbnail img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.story-thumbnail p {
  padding: 0.5rem;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.filter-left, .filter-right {
  display: flex;
  align-items: center;
}

.filter-left svg, .filter-right svg {
  margin-right: 0.5rem;
  color: #666;
}

.filter-left select, .filter-right select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

.filter-left select:focus, .filter-right select:focus {
  outline: none;
  border-color: #d8932b;
}

.filter-left {
  display: flex;
  align-items: center;
}

.filter-left svg {
  margin-right: 0.5rem;
  color: #666;
}

.filter-left select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

.filter-left select:focus {
  outline: none;
  border-color: #d8932b;
}

.filter-right {
  display: flex;
  align-items: center;
}

.story-thumbnail-container {
  position: relative;
}

.story-lesson-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.story-thumbnail:hover .story-lesson-hover {
  opacity: 1;
}

.story-lesson-hover p {
  padding: 10px;
  text-align: center;
}

.story-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
}

.like-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
  color: rgba(255, 192, 203, 0.7);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.like-button:hover {
  transform: scale(1.1);
  background-color: transparent;
  color: rgba(255, 192, 203, 0.9);
}

.like-button.liked {
  color: #ff0000;
}

.like-button:focus {
  outline: none;
}

.like-button:active {
  background-color: transparent;
}

.like-button svg {
  width: 20px;
  height: 20px;
}

.translate-button {
  position: absolute;
  top: 40px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
  color: rgba(102, 102, 102, 0.7);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

.translate-button:hover {
  color: #ffa51e;
  transform: scale(1.1);
  background-color: transparent;
}

.translate-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.translate-dialog {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.translate-dialog h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.translate-dialog select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
}

.dialog-buttons button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dialog-buttons button:first-child {
  background-color: #d8932b;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-buttons button:first-child svg {
  margin-right: 0.5rem;
}

.dialog-buttons button:last-child {
  background-color: #ccc;
}

.translate-dialog select:disabled,
.dialog-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.available-languages {
  position: absolute;
  top: 170px;
  right: 5px;
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2px;
  z-index: 1;
}

.available-languages span {
  font-size: 1em;
  cursor: default;
  line-height: 1;
}

.reading-level {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  padding: 2px;
  z-index: 2;
}

.reading-level-svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
}

.like-count {
  position: absolute;
  top: 28px;
  right: 10px;
  background: none;
  color: rgba(250, 62, 94, 0.7);
  font-size: small;
  font-weight: 900;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
