:root {
  --navbar-height: 60px;
  --navbar-color: #e1b064;
}

.navbar {
  background-color: var(--navbar-color);
  padding: 0.5rem 1rem 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 60px;
  width: auto;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: #101019;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin-left: 1rem;
}

.navbar-menu a,
.navbar-menu .logout-link {
  color: #101019;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
}

.navbar-menu a:hover,
.navbar-menu .logout-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    background-color: var(--navbar-color);
    padding: 1rem;
  }

  .navbar-menu.is-open {
    display: flex;
  }

  .navbar-menu li {
    margin: 0.5rem 0;
  }
}
