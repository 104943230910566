@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

.story-book {
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  user-select: none;
  position: relative;
}

.page {
  width: calc(100% - 20px);
  max-width: none;
  height: calc(100% - 20px);
  aspect-ratio: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  overflow: hidden;
}

.page-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page img {
  max-width: 95%;
  max-height: 85%;
  object-fit: contain;
  pointer-events: none;
  border-radius: 5px;
}

.page-content {
  font-size: 2.5dvh;
  /* margin-top: 2dvh; */
  padding: 0 2vw;
  pointer-events: none;
  white-space: pre-wrap;
  /* overflow-y: auto; */
  width: 100%;
}

.page-number {
  position: relative;
  text-align: center;
  font-size: 1.5dvh;
  color: #666;
  margin-top: 1dvh;
}

.current-page {
  z-index: 2;
}

.next-page {
  z-index: 1;
}

.transitioning {
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.current-page.transitioning {
  transform: translateX(100vw) rotate(20deg);
  opacity: 0;
}

.next-page.transitioning {
  opacity: 1;
  transition: none;
}

.done-reading-image {
  max-width: 200px !important;
  margin: 20px auto;
}

.like-story-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 8px 16px;
  margin: 16px 0;
  border: none;
  border-radius: 20px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
}

.like-story-button:hover {
  background-color: #fbd8d8;
}

.like-story-button.liked {
  background-color: #ff6b6b;
  color: white;
}

.like-story-button svg {
  margin-right: 8px;
  font-size: 1rem;
}

.like-story-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-link {
  display: inline-block;
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #d8932b;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.login-link:hover {
  background-color: #e0a143;
}

.story-title {
  font-size: 6dvh;
  color: #333;
  text-align: center;
  width: 100%;
  padding: 0 5vw;
  font-family: "Caveat", cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
}

.title-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
}

.browse-button,
.create-story-button,
.random-story-button,
.share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  margin: 10px 5px;
  font-size: 1rem;
  text-decoration: none;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 20px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  text-align: center;
  width: auto;
  white-space: nowrap;
}

.browse-button:hover,
.create-story-button:hover,
.random-story-button:hover,
.share-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.share-button {
  background-color: #cf8e39;
  color: white;
}

.share-button:hover {
  background-color: #e6a14d;
}

.share-message {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
  text-align: center;
}

.end-page-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 0 20px; /* Add padding for smaller screens */
}

.button-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Add styles for the icon */
.random-story-button svg {
  margin-right: 8px;
  flex-shrink: 0; /* Prevent icon from shrinking */
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .button-row {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping if needed */
  }

  .browse-button,
  .random-story-button,
  .create-story-button,
  .share-button {
    margin: 10px;
  }
}

/* Add a media query for very small screens */
@media (max-width: 320px) {
  .browse-button,
  .random-story-button,
  .create-story-button,
  .share-button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}

.language-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 20;
  max-height: 200px;
  overflow-y: auto;
  width: max-content;
  min-width: 120px;
}

.language-option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  font-size: 14px;
  color: #333;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.language-option:hover {
  background-color: #f0f0f0;
}

.translated-title {
  font-size: 4dvh;
  color: #555;
  text-align: center;
  width: 100%;
  padding: 0 5vw;
  font-family: "Caveat", cursive;
  margin-bottom: 1em;
}

.translated-subtitle {
  color: #666;
  margin-top: 0.5em;
  font-style: italic;
}

.end-title {
  font-size: 3dvh;
  color: #333;
  margin-bottom: 0.5rem;
}

.story-title.end-title {
  font-size: 4dvh;
  margin-bottom: 0px;
}

.end-page-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-top: 2rem;
}

.primary-action-button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  background-color: #D89756;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 1rem;
}

.primary-action-button:hover {
  background-color: #d27e29;
  transform: translateY(-2px);
}

.secondary-action-button {
  width: 100%;
  padding: 10px 16px;
  font-size: 1rem;
  color: #333;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 0.75rem;
}

.secondary-action-button:hover {
  background-color: #e0e0e0;
}

.secondary-action-button.liked {
  background-color: #ff6b6b;
  color: white;
}

.additional-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.text-link {
  color: #004eb5;
  text-decoration: none;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  transition: color 0.3s;
}

.text-link:hover {
  color: rgb(11, 64, 125);
  text-decoration: underline;
  cursor: pointer;
}

.info-text {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-top: 1rem;
}

.share-message {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #333;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .end-title {
    font-size: 3rem;
  }
  
  .end-page-actions {
    max-width: 250px;
  }
  
  .primary-action-button,
  .secondary-action-button {
    font-size: 0.9rem;
  }
}

.helper-text {
  position: absolute;
  bottom: 40px;
  color: rgb(79, 79, 79);
  padding: 8px 0px;
  border-radius: 40px;
  font-size: 24px;
  font-weight: 600;
  z-index: 10;
  display: flex;
  align-items: center;
}

.helper-text svg {
  font-size: 18px;
}

.helper-text:hover {
  opacity: 1;
}

/* Hide helper text when in fullscreen mode */
:fullscreen .helper-text {
  display: none;
}

/* For webkit browsers */
:-webkit-full-screen .helper-text {
  display: none;
}

/* For Firefox */
:-moz-full-screen .helper-text {
  display: none;
}

/* For IE/Edge */
:-ms-fullscreen .helper-text {
  display: none;
}
